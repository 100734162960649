h1 {
  /* font-family: Arial, Helvetica, sans-serif; */
  text-align: center;
  font-size: 2.5em;
  font-weight: 300;
  color: #fff;
}
.App {
  width: 100%;
  flex: 1;
}
.title-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.45rem;
  background: #305daa; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    left,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(
    right,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    right,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient(
    to right,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); /* Standard syntax */ /*header for page titles large size*/
}
.title {
  font-family: Arial, Helvetica, sans-serif;
  font-style: italic;
  font-size: 1.5rem;
}

.login-body {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.login-title {
  font-size: 2rem;
  font-weight: 300;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}
.alert {
  cursor: pointer;
}
body {
  background-color: #e3e8ee;
}
.item {
  width: 80%;
  margin-left: 10%;
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
}
.border-bottom {
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}
.item-title {
  font-size: 1.5rem;
  font-weight: 500;
  color: #3862ae;
  /* border-bottom: solid 1px rgba(0, 0, 0, 0.25); */
}
.item-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.item-buttons > button {
  flex: 1;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.card-wrapper {
  margin-left: 10%;
  width: 80%;
}
.item-body {
  white-space: pre-wrap;
}
.input-group-prepend {
  width: 6rem !important;
}
.input-group-text {
  width: 100%;
  font-weight: 700;
}
.btn-overlay {
  position: absolute;
  top: -1%;
  left: -1%;
  width: 102%;
  height: 102%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  transition-duration: 0.5s;
}
.button {
  position: relative;
  overflow: hidden;
}
.selected-pmt {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 0.5rem;
  overflow: hidden;
  transform: translateY(-0.5rem);
  transition-duration: 0.5s;
}
.selected-pmt:hover {
  transition-duration: 0.5s;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.35);
}
.selected-pmt > h5 {
  font-size: 1.6rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
}
.best-value {
  background-color: #6f42c1;
  /* background: linear-gradient(
    to right,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  ); */
  color: white;
  padding: 0.25rem;
  position: absolute;
  top: 0.55rem;
  left: -2.3rem;
  font-size: 0.5rem;
  transform: rotate(-45deg);
  width: 7rem;
  text-align: center;
}
.gear {
  position: absolute;
  top: 0;
  right: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.rotate-p {
  transition-duration: 0.5s;
  transform: rotate(0deg);
}
.rotate-a {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}
/* .gradient-fill {
  color: linear-gradient(
    to right,
    rgba(48, 93, 171, 1),
    rgba(48, 93, 171, 1),
    rgba(62, 138, 121, 1),
    rgba(75, 182, 73, 1),
    rgba(75, 182, 73, 1)
  );
} */
.settings-expand {
  transition-duration: 0.5s;
  overflow: hidden;
  height: 3em;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}
.settings-retract {
  transition-duration: 0.25s;
  overflow: hidden;
  height: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}
.more {
  cursor: pointer;
  margin-bottom: 3rem;
}
.alert-c {
  position: fixed;
  z-index: 1000;
  top: -25%;
  left: -25%;
  width: 150%;
  height: 150%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 35%;
}
.alert-body {
  padding: 1rem;
  border-radius: 5px;
  width: 40%;
  box-shadow: 0px 0px 5px #711c24;
}
.alert-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.alert-buttons > div {
  margin-left: 1rem;
}
.list-group-flush {
  margin-bottom: 1rem;
}
.border {
  border: solid 0px transparent !important;
  margin: 0.5rem;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.32);
  border-radius: 5px;
  padding: 0.5rem;
}
.btn-footer {
  border-top: solid 1px rgba(0, 0, 0, 0.15);
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.star {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.85rem;
}
.star > div {
  margin-left: 0rem;
  margin-right: 0.5rem;
}
.menu-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.menu-item-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  transition-duration: 0.25s;
}
.menu-item-wrapper-sel-bg {
  background-color: rgba(47, 93, 170, 0.12);
  border-radius: 4px;
  transition-duration: 0.25s;
}
.menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.menu-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 1rem;
  padding-left: 2rem;
}

.content-wrapper {
  flex: 1;
}

.menu-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition-duration: 0.25s;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.menu-item-sm {
  font-size: 0.75rem;
  margin-left: 0.5rem;
  cursor: pointer;
  white-space: nowrap;
}
.menu-item-sm:nth-last-child(1) {
  margin-bottom: 0.5rem;
}
.menu-item > div:nth-child(1) {
  margin-right: 0.25rem;
  flex: 0.5;
}
.menu-item > div:nth-child(2) {
  flex: 5;
}
.flex-body {
  display: flex;
  width: 80%;
  margin-left: 5%;
}
.flex-1 {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.menu-item-sub {
  transition-duration: 0.25s;
  height: 0px;
  overflow: hidden;
}
.menu-item-sub-expand {
  transition-duration: 0.25s;
  overflow: hidden;
}
.menu-sel {
  font-weight: 700;
  transition-duration: 0.125s;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row > div {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.flex-row > input {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.footer {
  height: 6vh;
  width: 100%;
  background-color: rgba(48, 93, 171, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 100;
}
.text-primary {
  color: rgba(48, 93, 171, 1) !important;
}
.body {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.list-group-item.custom {
  border-radius: 0px;
  border-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.list-group-item.custom > div {
  display: flex;
  flex-direction: row;
}
.list-group-item.custom > div:nth-child(1) > div:nth-child(1) {
  font-weight: 700;
}
.list-group-item.custom > div > div {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.list-group
  > div:nth-last-child(1)
  > .list-group-item.custom:nth-last-child(1) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group > div:nth-child(1) > .list-group-item.custom:nth-child(1) {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
i {
  cursor: pointer;
}
.file-image {
  flex: 1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-color: rgba(0, 0, 0, 0.15);
}
.custom-file-label {
  border-top-left-radius: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5rem;
}
#custom-submit-image {
  border-top-right-radius: 0px !important;
  border: solid 1px rgba(0, 0, 0, 0.15);
  font-weight: 400;
}
.custom-file-input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
}
.text-p {
  color: #007bff;
}
.upload-wrapper {
  display: flex;
  flex-direction: "row";
}
.upload-wrapper > div {
  flex: 1;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.progress-uploader {
  flex: 1;
  background-color: #f3f5f8;
  border-radius: 4px;
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, 0.125);
}

.progress-uploader > div {
  transition-duration: 0.25s;
  height: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.375rem;
  font-weight: 700;
}
.progress-uploader-empty {
  width: 100%;
  background-color: #f3f5f8;
  border-radius: 4px;
  overflow: hidden;
  border: solid 1px rgba(0, 0, 0, 0.125);
}
.progress-uploader-empty > div {
  transition-duration: 0.25s;
  height: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 2rem;
  font-weight: 700;
}
.hover-danger {
  transition-duration: 0.125s;
}
.hover-danger:hover {
  transition-duration: 0.125s;
  color: #dc3545;
}
.hover-primary {
  transition-duration: 0.125s;
}
.hover-primary:hover {
  transition-duration: 0.125s;
  color: #007bff;
}

.icons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.hidden-input {
  border: none;
  background: transparent;
  outline: none;
  width: 100%;
}
.hidden-input-sm {
  border: none;
  background: transparent;
  outline: none;
  width: 70%;
}
.hidden-textarea {
  border: none;
  background: transparent;
  outline: none;
  resize: none;
  width: 100%;
}
.dt {
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
  font-size: 0.75rem;
}
.dragging {
  transition-duration: 0.5s;
  width: 30% !important;
  background-color: #007bff;
  color: white;
}
.list-group-item {
  transition-duration: 0.5s;
  width: 100%;
}
.sharp {
  border-radius: 0px !important;
}
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000;
}
.modal-body {
  position: relative;
  padding: 1rem;
  margin-bottom: 10rem;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 4px;
  background-color: white;
  max-width: 40rem;
  height: 32.5rem;
}
.modal-chart-wrapper {
  display: flex;
  flex-direction: row;
}
.modal-chart {
  height: 25rem;
  width: 30rem;
  padding: 1rem;
}
.modal-chart-header {
  color: black;
  font-size: 1.5rem;
}
.modal-likes {
  display: flex;
  flex-direction: row;
  height: 1rem;
  border-radius: 4px;
  overflow: hidden;
}
.modal-likes > div:nth-child(1) {
  background-color: #30c752;
  transition-duration: 0.125s;
}
.modal-likes > div:nth-last-child(1) {
  background-color: #d82537;
  transition-duration: 0.125s;
}
.scroll {
  max-height: 80vh;
  overflow: scroll;
}
.bg-disabled {
  background-color: #b8bbb9;
}
.cel {
  padding: 0.5rem;
  transition-duration: 0.125s;
  text-align: center;
}
.cel:hover {
  background-color: rgba(0, 0, 0, 0.12);
  transition-duration: 0.125s;
  cursor: pointer;
}
.cel-selected {
  padding: 0.5rem;
  transition-duration: 0.125s;
  text-align: center;
  background-color: #4ab549;
  color: white;
}
.timeline {
  height: 10rem;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
}
#video {
  border-radius: 4px;
}
.timeline-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.04);
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  height: 2rem;
}
.timeline-event {
  color: white;
  padding: 0.5rem;
}
.timeline-time {
  border-left: solid 1px rgba(0, 0, 0, 0.15);
  padding: 0.25rem;
  width: 8rem;
  text-align: center;
}
.timeline-resize {
  width: 16px;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: ew-resize;
}
.timeline-wrapper {
  position: absolute;
  top: 2rem;
  height: 8rem;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.6);
  /* #30c752 */
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
.scrub-bar {
  position: absolute;
  top: 0rem;
  height: 10rem;
  width: 2px;
  z-index: 1000;
  background-color: #dc3545;
  /* transition-duration: 0.01s; */
  transition-timing-function: linear;
}
.grabbing {
  cursor: grabbing;
}
.grab {
  cursor: grab;
}
.scrub-thumb {
  background-color: #dc3545;
  position: absolute;
  top: 0rem;
  left: -4rem;
  width: 8rem;
  z-index: 100;
  padding: 0.25rem;
  border-radius: 4px;
  color: white;
  /* transition-duration: 0.01s; */
  display: flex;
  justify-content: center;
  font-weight: 700;
}
.scrub-bar-live {
  position: absolute;
  top: 0rem;
  height: 10rem;
  width: 2px;
  z-index: 999;
  background-color: #28a745;
  transition-duration: 10s;
  transition-timing-function: linear;
}
.scrub-thumb-live {
  background-color: #28a745;
  position: absolute;
  top: 0rem;
  left: -2.5rem;
  width: 5rem;
  z-index: 100;
  padding: 0.25rem;
  border-radius: 4px;
  color: white;
  transition-duration: 10s;
  display: flex;
  justify-content: center;
  font-weight: 700;
}
.agenda-btns {
  margin-bottom: 0rem;
  border-radius: 4px;
  /* background-color: rgba(0, 0, 0, 0.12); */
  padding: 0.5rem;
  display: flex;
  justify-content: flex-start;
  /* border: solid 1px rgba(0, 0, 0, 0.12); */
  border-bottom: none;
}
.agenda-btns > button:nth-child(1) {
  border: none !important;
  border-top-left-radius: 6px !important;
  border-bottom-left-radius: 6px !important;
}
.agenda-btns > button:nth-last-child(1) {
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.agenda-btns > button {
  border-left: solid 1px rgba(0, 0, 0, 0.32);
}
.email-header {
  font-weight: 500;
  font-size: 2rem;
}
.email-title {
  background-color: white;

  width: 60%;
  margin-left: 20%;
}
.email-row {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 60%;
  margin-left: 20%;
  margin-top: 2%;
  margin-bottom: 2%;
}
.email-content:nth-child(1) {
  background-color: white;
  width: 48%;
  margin-right: 2%;
}
.email-content:nth-child(2) {
  background-color: white;
  width: 48%;
  margin-left: 2%;
}
.email-title-header {
  font-weight: 700;
  font-size: 1.25rem;
  padding: 1rem;
  padding-bottom: 0.5rem;
}
.email-title-banner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 15rem;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0.04);
  position: relative;
}
.email-title-body {
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.email-title-btn {
  padding: 1rem;
  padding-top: 0.5rem;
}
.upload-image {
  background-color: #d9d9d9;
  height: 2.5rem;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5rem;
  color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
}
.file-item-menu {
  position: absolute;
  bottom: 0;
  right: -5rem;
  cursor: pointer;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  z-index: 1000;
}
.file-item-menu > .list-group > .list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader > i:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  color: #007bff;
  animation-name: loader;
  animation-direction: normal;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: 100;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.caption-editor {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}
.ag-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.ag-main-content {
  width: 80%;

  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.ag-menu-item-title {
  font-weight: 500;
}
.ag-side-menu {
  width: 20%;
  max-height: 75vh;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
}

.ag-side-menu-item {
}
.ag-menu-item-selected {
  /* #30c752 */
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  transition-duration: 0.05s;
}
.ag-video-wrapper {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cc-wrapper {
  position: relative;
  width: 100%;
  height: 95%;
  /* margin: 0; 
  background-color: red;  */
}
.border-right {
  border-right: solid 1px black;
}
.tooltiptext {
  display: none;
}
.tool-tip {
  display: inline;
}
.ag-menu-close {
  position: absolute;
  top: 0;
  right: 0.25rem;
  z-index: 1000;
}
.strong {
  font-weight: 600;
}
.bg-light-gray {
  background-color: rgba(0, 0, 0, 0.04);
}
.sm-font {
  font-size: 0.9rem;
}
.menu-spacer {
  height: 0.5rem;
}

.metadata-list {
  position: absolute;
  bottom: 0;
  left: -24rem;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  z-index: 1000;
  padding: 0;
}
.metadata-list > div > .list-group {
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  width: 8rem;
}

.metadata-list > div > .list-group > .list-group-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
.btn-hover-wrapper {
  position: absolute;
  top: -0.5rem;
  left: -0.4rem;
  display: flex;
  flex-direction: row;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 300;
}
.btn-hover {
  width: 2rem;
  padding: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid 1px rgba(0, 0, 0, 0.08);
}
.btn-hover:hover {
  background-color: rgba(0, 0, 0, 0.04);
  transition-duration: 0.25s;
}
.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress {
  stroke: #4bb649;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.bold {
  font-weight: 700;
}
.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: red;
}
.color-picer-input {
  width: 20rem;
}
.color-picker {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.color-picker > div:nth-child(1) > div:nth-child(1) {
  border-top-left-radius: 4px;
}
.color-picker > div:nth-child(1) > div:nth-last-child(1) {
  border-bottom-left-radius: 4px;
}
.color-picker > div:nth-last-child(1) > div:nth-child(1) {
  border-top-right-radius: 4px;
}
.color-picker > div:nth-last-child(1) > div:nth-last-child(1) {
  border-bottom-right-radius: 4px;
}
.color-item-selected {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.48);
  transform: translateZ(1px);
  transition-duration: 0.25s;
  outline: solid 1px black;
  border: solid 1px white;
}
.color-item {
  transition-duration: 0.25s;
}
.color-item:hover {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.4);
  transform: translateZ(1px);
  transition-duration: 0.25s;
}
.swatch {
  height: 35px;
  width: 35px;
  border-radius: 4px;
  margin-top: 1px;
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.banner-image {
  height: 5rem;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.custom-input-prepend {
  background-color: rgba(0, 0, 0, 0.08);
  font-weight: 500 !important;
}
.delete-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.04);
  padding: 0.75rem;
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  margin-right: 0rem !important;
}
.btn-primary {
  background-color: #2196f3;
  border: none;
  outline: none;
}
/* .MuiSlider-colorPrimary {
  background-color: red;
} */
.purchase-title {
  font-size: 1.25rem;
  border-bottom: solid 1px rgba(0, 0, 0, 0.24);
  width: 30%;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  font-weight: 500;
}
.purchase-title > img {
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  display: block;
  max-width: 3rem;
  /* max-height: 3.5rem; */
  /* width: auto; */
  height: auto;
  border: none;
  background-color: none;
}

.purchase-group {
  margin-left: 1rem;
  margin-right: 1rem;
  transition-duration: 0.25s;
}
.checkout-btn-wrapper {
  position: relative;
  height: 2rem;
}
.checkout-btn {
  position: absolute;
  z-index: 10;
}
.checkout-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 4px;

  animation-name: checkout;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  /* animation-direction: alternate-reverse; */
}
.checkout-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 4px;

  animation-name: checkout;
  animation-timing-function: linear;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  /* animation-direction: alternate-reverse; */
  animation-delay: 0.00125s;
}
@keyframes checkout {
  from {
    transform: scaleX(1.05) scaleY(1.1);
    background-color: rgba(75, 182, 74, 0.4);
  }
  to {
    transform: scaleX(1.15) scaleY(1.5);
    background-color: rgba(75, 182, 74, 0);
  }
}
.narrow-checkout {
  width: 50%;
  margin-left: 25%;
}
.list-group-alternating > li:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.04);
}
.sort-row > .col {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  justify-content: center;
}
.sort-row > .col:nth-child(1) {
  border-left: solid 1px rgba(0, 0, 0, 0);
}
.nice-input:focus {
  border: solid 2px #2196f3;
  outline: none;
  transition-duration: 0.25s;
}
.nice-input {
  background-color: transparent;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.4);
  padding-left: 0.7rem;
  transition-duration: 0.25s;
  padding: 0.25rem;
}
.input-wrapper {
  position: relative;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 2.7rem;
}
.input-wrapper > p {
  position: absolute;
  top: -0.375rem;
  left: 0.5rem;
  background-color: white;
  font-size: 0.75rem;
  padding-left: 4px;
  padding-right: 4px;
}
.video-player {
  border-radius: 4px;
  height: 100%;
}
.input-icon {
  position: absolute;
  right: 1px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.tab-row {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.tab-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 4px rgba(0, 0, 0, 0.12);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  transition-duration: 0.25s;
}
.tab-title-selected {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-bottom: solid 4px #305dab;
  color: #305dab;
  cursor: pointer;
  transition-duration: 0.25s;
}
.tab-list-item-title {
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.caption-position {
  width: 94%;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 3%;
  height: 10%;
}
.hyperlink-btn {
  background-color: rgba(0, 0, 0, 0.16);
  color: white;
  outline: none;
  border: solid 1px rgba(0, 0, 0, 0.24);
}
.hyperlink-btn:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: white;
  outline: none;
  border: solid 1px rgba(0, 0, 0, 0.16);
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.color-swatch {
  height: 1.75rem;
  width: 1.75rem;
  margin: 0rem !important;
  display: block;
  cursor: pointer;
}
.color-swatch-sel {
  box-shadow: 0px 0px 2px black;
  border: solid 1px white;
}
.color-swatch:hover {
  box-shadow: 0px 0px 5px black;
}
.color-swatch:nth-child(1) {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.color-swatch:nth-last-child(1) {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
